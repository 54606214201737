<template>
  <div class="row">
    <div class="col-12 col-sm-6 d-flex flex-column">
      <span class="unit-selector-label">COMPRAR AULAS EM:</span>

      <select class="unit-selector-select" v-model="locationSelected">
        <option :value="null" disabled="disabled">Selecione uma opção</option>
        <template v-for="(item, key) in locations">
          <option :value="item.id" :key="key">{{ item.name }}</option>
        </template>
      </select>
    </div>

    <div class="col-12 col-sm-6 d-flex flex-column unit-selector-step">
      <template v-if="disableUnitStep">
        <div class="unit-selector-blocker"></div>
      </template>

      <span class="unit-selector-label">UNIDADE:</span>

      <select class="unit-selector-select" v-model="posSelected" :disabled="disableUnitStep">
        <option :value="null" disabled="disabled">Selecione uma opção</option>
        <template v-for="(item, key) in posList">
          <option :value="item.id" :key="key">{{ item.name }}</option>
        </template>
      </select>

      <div class="custom-control custom-checkbox">
        <input
          type="checkbox"
          class="custom-control-input"
          id="remember-selection"
          v-model="useCache"
          :disabled="disableUnitStep"
        />
        <label class="custom-control-label" for="remember-selection"
          >Lembrar esta cidade/unidade</label
        >
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.unit-selector-hide {
  display: none;
}

.unit-selector-label {
  font-size: 14px;
  font-weight: bold;
  color: #000;
}

.unit-selector-select {
  border: 1px solid #000 !important;
}

.unit-selector-step {
  position: relative;
}

.unit-selector-blocker {
  height: 100%;
  width: 100%;
  opacity: 0.7;
  position: absolute;
  top: 0;
  background: rgba(245, 245, 245, 1);
  z-index: 50;
}
</style>

<script>
class Cache {
  static get key() {
    return 'unit-package-selector';
  }

  static set(data) {
    return window.localStorage.setItem(
      this.key,
      JSON.stringify({
        ...this.get(),
        ...data,
      })
    );
  }

  static get() {
    return JSON.parse(window.localStorage.getItem(this.key) || '{}');
  }

  static has() {
    return Boolean(this.get());
  }
}

export default {
  props: ['packageWidgetSelector', 'tags'],

  data() {
    return {
      posSelected: null,
      locationSelected: null,
      useCache: false,
      disableUnitStep: true,
      posList: [],
      locations: [],
    };
  },

  created() {
    this.hidePackages();

    this.getLocations().then((locations) => {
      this.locations = locations;

      const { rememberSelection, pos, location } = Cache.get();

      this.useCache = rememberSelection === true;

      if (this.useCache) {
        if (location) {
          this.locationSelected = Number(location);
        }

        if (pos) {
          this.posSelected = Number(pos);
        }
      }
    });
  },
  mounted(){
    if(window.location.search != ''){
      let url = window.location.search;

      let objURL = this.getUrlVars(url);

      if(!this.locationSelected && objURL.location_list){
        this.locationSelected = objURL.location_list;
      }
      if(!this.posSelected && objURL.pos_list){
        this.posSelected = objURL.pos_list;
      }  
    }
  },
  watch: {
    useCache(value) {
      Cache.set({ rememberSelection: value });
    },
    locationSelected(value) {
      this.disableUnitStep = false;
      Cache.set({ location: value });
      this.hidePackages();
      this.posList = [];
      this.getPOSList().then((results) => (this.posList = results));
    },
    posSelected(value) {
      Cache.set({ pos: value });
      this.setPOS().then(() => this.loadPackages());
    },
  },

  computed: {
    packageWidgetEl() {
      return window.document.querySelector(this.packageWidgetSelector);
    },
  },

  methods: {
    getUrlVars(url) {
        var hash;
        var myJson = {};
        var hashes = url.slice(url.indexOf('?') + 1).split('&');
        for (var i = 0; i < hashes.length; i++) {
            hash = hashes[i].split('=');
            myJson[hash[0]] = hash[1];
        }
        return myJson;
    },
    hidePackages() {
      this.packageWidgetEl.classList.add('unit-selector-hide');
    },
    showPackages() {
      this.packageWidgetEl.classList.remove('unit-selector-hide');
    },
    loadPackages() {
      /* 
        Quando os dados vem de preseleção o componente de pacotes não foi carregado ainda.
        Interval permite rodar o código apenas quando o componente existir.
      */
      const interval = window.setInterval(() => {
        const packages = this.packageWidgetEl.querySelector('ae-package-list-multi-location');
        if (!packages) {
          return;
        }

        window.clearInterval(interval);

        packages.addEventListener('dataLoaded', () => {
          this.showPackages();
        });

        packages.posId = this.posSelected;

        packages.params = {
          pos_list: this.posSelected,
          is_noshow_penalty: false,
          in_catalogue: true,
          active: true,
        };
      }, 100);
    },
    getLocations() {
      return new Promise((resolve) => {
        const locations = [];

        const paginator = window.AngularEcommerce.ws.locations.getAll({
          params: {
            enabled: true,
            use_as_filter: true,
            tag: this.tags,
          }
        });

        paginator.getAllPages((res) => {
          locations.push(...res.data.results);

          if (!paginator.hasNextPage()) {
            resolve(locations);
          }
        });
      });
    },
    getPOSList() {
      return new Promise((resolve) => {
        const posList = [];

        const paginator = window.AngularEcommerce.ws.pointsOfSale.getAll({
          params: {
            is_online: true,
            tag: this.tags,
            location_list: this.locationSelected,
          },
        });

        paginator.getAllPages((res) => {
          posList.push(...res.data.results);

          if (!paginator.hasNextPage()) {
            resolve(posList);
          }
        });
      });
    },
    setPOS() {
      return window.AngularEcommerce.ws.checkout.get().then(({ data }) => {
        if (data.pos_id === this.posSelected) {
          return Promise.resolve();
        }

        return window.AngularEcommerce.ws.checkout.setPOS('current', {
          point_of_sale: this.posSelected,
        });
      });
    },
  },
};
</script>