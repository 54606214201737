<template>
  <div class="row">
    <div class="col-12 col-sm-6 d-flex flex-column mb-3">
      <span ref="geturl" @click="getUrl()"></span>
      <span class="unit-selector-label">CIDADE</span>

      <select class="unit-selector-select" v-model="locationSelected">
        <option :value="null" disabled="disabled">Selecione uma opção</option>
        <template v-for="(item, key) in locations">
          <option :value="item.id" :key="key">{{ item.name }}</option>
        </template>
      </select>
    </div>

    <div class="col-12 col-sm-6 d-flex flex-column unit-selector-step">
      <template v-if="disableUnitStep">
        <div class="unit-selector-blocker"></div>
      </template>

      <span class="unit-selector-label">UNIDADE</span>

      <select class="unit-selector-select" v-model="unitSelected" :disabled="disableUnitStep">
        <option :value="null" disabled="disabled">Selecione uma opção</option>
        <template v-for="(item, key) in unitList">
          <option :value="item.id" :key="key">{{ item.name }}</option>
        </template>
      </select>
    </div>
    <div class="col-md-12">
      <p v-if="load" class="text-center mt-5" style="text-align:center;">
        <svg width="20" height="20" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#000">
            <g fill="none" fill-rule="evenodd">
                <g transform="translate(1 1)" stroke-width="2">
                    <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
                    <path d="M36 18c0-9.94-8.06-18-18-18">
                        <animateTransform
                            attributeName="transform"
                            type="rotate"
                            from="0 18 18"
                            to="360 18 18"
                            dur="1s"
                            repeatCount="indefinite"/>
                    </path>
                </g>
            </g>
        </svg>
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.unit-selector-hide {
  display: none;
}

.unit-selector-label {
  font-size: 14px;
  font-weight: bold;
  color: #000;
}

.unit-selector-select {
  border: 1px solid #000 !important;
}

.unit-selector-step {
  position: relative;
}

.unit-selector-blocker {
  height: 100%;
  width: 100%;
  opacity: 0.7;
  position: absolute;
  top: 0;
  background: rgba(245, 245, 245, 1);
  z-index: 50;
}
.inactive-brand-logo {
  opacity: 0.3;
  cursor: not-allowed;
}
</style>

<script>
import { isSmallTablet, isTablet, isPhone } from '../../functions/mq.js';
class Cache {
  static get key() {
    return 'unit-package-selector';
  }

  static set(data) {
    return window.localStorage.setItem(
      this.key,
      JSON.stringify({
        ...this.get(),
        ...data,
      })
    );
  }

  static get() {
    return JSON.parse(window.localStorage.getItem(this.key) || '{}');
  }

  static has() {
    return Boolean(this.get());
  }
}

export default {
  props: ['packageWidgetSelector', 'tag', 'event_type'],

  data() {
    return {
      unitSelected: null,
      locationSelected: null,
      useCache: false,
      disableUnitStep: true,
      unitList: [],
      locations: [],
      tags: null,
      paramsUrl:null,
      key:1,
      searchByUrl:false,
      load: false
    };
  },

  created() {
    this.hideCalendar();

    this.getLocations().then((locations) => {
      this.locations = locations;

      //Função que retira location com flag online do select
      let keyOnline = [];
      this.locations.map((item)=>{
        item.tags.map((item2, i2)=>{
          if(item2=='online') keyOnline.push(i2);
        });
      });

      keyOnline.map((item)=>{
        this.locations.splice(keyOnline, item);
      });
      

      const { rememberSelection, unit, location } = Cache.get();

      this.useCache = rememberSelection === true;

      if (this.useCache) {
        if (location) {
          this.locationSelected = Number(location);
        }

        if (unit) {
          this.unitSelected = Number(unit);
        }
      }
    });
  },
  mounted() {
    if (window.location.search != '') {
      
      let varsURL = this.getUrlVarsToUrlBrand(window.location.href);
      if(varsURL && varsURL['start-at']) delete varsURL['start-at'];
      if(varsURL.length) this.searchByUrl = true;

      this.$refs.geturl.click();

      let url = window.location.search;

      let objURL = this.getUrlVars(url);

      if (!this.locationSelected && objURL.location) {
        this.locationSelected = objURL.location;
      }
      if (!this.unitSelected && objURL.units) {
        this.unitSelected = objURL.units;
      }
    }
  },
  watch: {
    useCache(value) {
      Cache.set({ rememberSelection: value });
    },
    locationSelected(value) {
      this.disableUnitStep = false;
      Cache.set({ location: value });
      this.hideCalendar();
      this.unitList = [];
      const url = new URL(document.location).searchParams;
      url.delete('unit_list');
      window.history.replaceState({}, undefined, `?${url.toString()}`);
      this.getunitList().then((results) => (this.unitList = results));
      this.unitSelected = null;
    },
    unitSelected(value) { 
      if(this.key == 1 && this.searchByUrl){
        this.key++;
        this.searchByUrl = false;
        this.showCalendar(); 
        return;
      }
      this.load = true;
      Cache.set({ unit: value });
      let mileseconds = (this.key==1)?2000:200;
      this.$refs.geturl.click();
      setTimeout(()=>{
        this.getTagsByIdUnit(value);
        this.loadCalendar();
        this.key++;
        this.load = false;
      }, mileseconds);
      
    },
    paramsUrl(value){
      if(('units' in value) && ('location' in value)){
        this.locationSelected = value.location;
        this.unitSelected = value.units;
      }
    }
  },

  computed: {
    packageWidgetEl() {
      return window.document.querySelector(this.packageWidgetSelector);
    },
    isMobile() {
      return isTablet() || isSmallTablet() || isPhone();
    },
  },

  methods: {
    getDomainActive(domain){
      if(document.location.origin === domain) return false;
      else return true;
    },
    urlFilter(siteUrl) {
      window.location.href = siteUrl + window.location.search;
    },
    isHaveTag(brand) {
      if (!this.tags) return false;
      return this.tags.filter((fill) => fill === brand).length;
    },
    getTagsByIdUnit(id) {
      setTimeout(()=>{
        let tag = this.unitList;

        //let tags = tag.filter((fill) => fill.id === id);
        tag.map(item=>{
          if(item.id == id) this.tags = item.tags;
        });
      });
      
    },
    getUrlVars(url) {
      var hash;
      var myJson = {};
      var hashes = url.slice(url.indexOf('?') + 1).split('&');
      for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        myJson[hash[0]] = hash[1];
      }
      this.paramsUrl = myJson;
      return myJson;
    },
    getUrlVarsToUrlBrand(url) {
      var hash;
      var myJson = {};
      var hashes = url.slice(url.indexOf('?') + 1).split('&');
      for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        myJson[hash[0]] = hash[1];
      }
      return myJson;
    },
    hideCalendar() {
      if(this.packageWidgetEl) this.packageWidgetEl.classList.add('unit-selector-hide');
      //this.packageWidgetEl.classList.add('unit-selector-hide');
    },
    showCalendar() {
      if(this.packageWidgetEl) this.packageWidgetEl.classList.remove('unit-selector-hide');
      //this.packageWidgetEl.classList.remove('unit-selector-hide');
    },
    loadCalendar() {
      
      if (!this.unitSelected) {
        return;
      }
      /* 
        Quando os dados vem de preseleção o componente de pacotes não foi carregado ainda.
        Interval permite rodar o código apenas quando o componente existir.
      */
      const interval = window.setInterval(() => {
        const calendar = this.packageWidgetEl.querySelector('ae-weekly-calendar');
        if (!calendar) {
          return;
        }

        window.clearInterval(interval);
        this.showCalendar();
        calendar.isExternalFilters = true;
        calendar.config = {
          eventParams: {
            units: this.unitSelected,
            location: this.locationSelected,
            event_type:this.event_type
            //activity_list: this.activityId,
          },
        };
      }, 100);
    },
    getLocations() {
      return new Promise((resolve) => {
        const locations = [];

        const paginator = window.AngularEcommerce.ws.locations.getAll({
          params: {
            enabled: true,
            use_as_filter: true
            //activity_list: this.activityId,
          },
        });

        paginator.getAllPages((res) => {
          locations.push(...res.data.results);

          if (!paginator.hasNextPage()) {
            resolve(locations);
          }
        });
      });
    },
    getunitList() {
      return new Promise((resolve) => {
        const unitList = [];

        const paginator = window.AngularEcommerce.ws.units.getAll({
          params: {
            is_online: true,
            //activity_list: this.activityId,
            location_list: this.locationSelected,
            tag:this.tag
          },
        });

        paginator.getAllPages((res) => {
          unitList.push(...res.data.results);

          if (!paginator.hasNextPage()) {
            resolve(unitList);
          }
        });
      });
    },
    getUrl(){
      let mileseconds = this.key == 1? 3000 : 500;
      setTimeout(()=>{
        let tag = this.unitList;
        let id = this.unitSelected;
        let currentParamsJson =this.getUrlVarsToUrlBrand(window.location.href);
        delete currentParamsJson.activity_list;
        let currentParamsQueryString =this.jsonToQueryString(currentParamsJson);
        
        tag.map(item=>{
          if(item.id == id) this.tags = item.tags;
        });

        if(!this.tags) return;

        document.querySelector('#race_sso') ? document.querySelector('#race_sso').href = 'https://racebootcamp.com.br/horarios' : '';
        document.querySelector('#tonus_sso') ? document.querySelector('#tonus_sso').href = 'https://tonusgym.com.br/horarios' : '';
        document.querySelector('#vidya_sso') ? document.querySelector('#vidya_sso').href = 'https://vidyastudio.com.br/horarios' : '';
        document.querySelector('#jab_sso') ? document.querySelector('#jab_sso').href = 'https://jabhouse.com.br/horarios' : '' ;

        this.tags.map(item=>{
          if(item == 'race' && document.querySelector('#race_sso')) document.querySelector('#race_sso').href = 'https://racebootcamp.com.br/horarios'+currentParamsQueryString;
          if(item == 'tonus' && document.querySelector('#tonus_sso')) document.querySelector('#tonus_sso').href = 'https://tonusgym.com.br/horarios'+currentParamsQueryString;
          if(item == 'vidya' && document.querySelector('#vidya_sso')) document.querySelector('#vidya_sso').href = 'https://vidyastudio.com.br/horarios'+currentParamsQueryString;
          if(item == 'jab' && document.querySelector('#jab_sso')) document.querySelector('#jab_sso').href = 'https://jabhouse.com.br/horarios'+currentParamsQueryString;
        });

        
      },mileseconds);
    },
    jsonToQueryString(json) {
        return '?' + 
            Object.keys(json).map(function(key) {
                return encodeURIComponent(key) + '=' +
                    encodeURIComponent(json[key]);
            }).join('&');
    }
  },
};
</script>